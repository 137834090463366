// components
import PageLayout from 'src/components/PageLayout';
import Intro from 'src/components/Intro';
// hooks
import useBlogIntroData from 'src/cms/data/blog/useBlogIntroData';
import useBlogMetaData from 'src/cms/data/blog/useBlogMetaData';
// sections
import Posts from './sections/Posts';
import { FC } from 'react';
import { PageProps } from 'gatsby';

const Blog: FC<PageProps> = ({ location }) => {
  // hooks
  const { title, mobileTitle, description } = useBlogIntroData();
  const blogMetaData = useBlogMetaData();

  return (
    <PageLayout
      withStartProjectForm={false}
      metaData={{ ...blogMetaData, href: location.href }}
    >
      <Intro
        title={title}
        mobileTitle={mobileTitle}
        description={description}
        icon='blogDrawing'
      />
      <Posts />
    </PageLayout>
  );
};

export default Blog;
