import { FC, Fragment } from 'react';
// components
import Section from 'src/components/Section';
import Post from './Post';
// hooks
import usePostsData from 'src/cms/data/blog/usePostsData';

const Posts: FC = () => {
  // hooks
  const { postList } = usePostsData();

  return (
    <Section>
      {postList.map((post) => (
        <Fragment key={post.id}>
          <Post {...post} />
        </Fragment>
      ))}
    </Section>
  );
};

export default Posts;
