import { FC, memo, useEffect, useRef } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
// animations
import { showFromOpacity } from 'src/animations/block';
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';
// components
import Typography from 'src/components/Typography';
import Button from 'src/components/Button';
// types
import { PostBlog } from 'src/cms/data/blog/usePostsData';
// styles
import * as style from './Posts.module.scss';

const Post: FC<PostBlog> = memo(({ id, title, image, description }) => {
  const postRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (postRef.current !== null) {
      const animation = showFromOpacity(postRef.current, {
        duration: 2,
      }).pause();

      onHTMLElementEnterOnWindow(postRef.current, () => {
        animation.play();
      });
    }
  }, []);

  return (
    <div className={style.post} ref={postRef}>
      <div className={style.image}>
        <div className={style.imageWrapper}>
          <Link to={`/blog/${id}`}>
            <GatsbyImage
              alt={title}
              image={image.childImageSharp.gatsbyImageData}
            />
          </Link>
        </div>
      </div>
      <div className={style.contentWrapper}>
        <div className={style.content}>
          <Link to={`/blog/${id}`}>
            <Typography className={style.title} variant='h2'>
              {title}
            </Typography>
            <Typography className={style.description} variant='body'>
              {description}
            </Typography>
            <Button
              className={style.more}
              text='Read More'
              iconRightName='arrowRight'
              variant='secondary'
            />
          </Link>
        </div>
      </div>
    </div>
  );
});

export default Post;
